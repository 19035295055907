(function() {
'use strict';


	/* ------------------------------------------------------------
		画像など含めてページ読込み完了後に実行
	* ------------------------------------------------------------ */
	// ----------------------------------------
	// 要素の高さ揃え
	// ----------------------------------------
	function heightAlign() {
		$('.database__item-link').matchHeight();
	}
	$(window).on('load', function() {
		heightAlign();
	});

	/* ------------------------------------------------------------
		DOM構築後実行
	* ------------------------------------------------------------ */
	$(function() {

		/* 実行
		-------------------------------------------------- */
		$(function() {
		});

	});

}).call(this);
